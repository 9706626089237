import React, { Component } from 'react'
import Link from '../utils/link'

import Logo from '../assets/images/logo.svg'
import LogoScrolled from '../assets/images/logo-filled.svg'
import Srl from '../assets/images/srl.svg'

import isElementInViewport from '../utils/viewport-check'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _scrollTo = (element) => {
    this.setState({ offCanvas: false })
    window && window.scroll({ 
      top: document&& document.querySelector(element).offsetTop - 70,
      left: 0,
      behavior: 'smooth'
    })
  }

  onScroll = () => {
    this.setState({
      scrolled: window.scrollY > (window.innerHeight - 100),
    })
  }

  render() {

    let { offCanvas, scrolled } = this.state

    return (
      <>
        <header className={`header ${scrolled ? 'header--scrolled' : ''}`}>
          <div className='header__inner'>
            <Link to='/' title='Site Name' className='header__logo'>
              <img className='header__logo--initial' src={Logo} alt="O'Herns Logistics Park" />
              <img className='header__logo--scrolled' src={LogoScrolled} alt="O'Herns Logistics Park" />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li><span onClick={() => this._scrollTo('#overview')}>Overview</span></li>
                <li><span onClick={() => this._scrollTo('#location')}>Location</span></li>
                <li><span onClick={() => this._scrollTo('#sustainability')}>Sustainability</span></li>
                <li><span onClick={() => this._scrollTo('#contact')}>Contact</span></li>
              </ul>
            </nav>
            <nav className='header__nav header__nav--disclaimer'>
                <ul>
                  <li><span onClick={() => window && window.history.back()}>Back</span></li>
                </ul>
              </nav>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
          <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
            <div className='off-canvas__inner'>
              <nav className='off-canvas__nav'>
                <ul>
                  <li><span onClick={() => this._scrollTo('#overview')}>Overview</span></li>
                  <li><span onClick={() => this._scrollTo('#location')}>Location</span></li>
                  <li><span onClick={() => this._scrollTo('#sustainability')}>Sustainability</span></li>
                  <li><span onClick={() => this._scrollTo('#contact')}>Contact</span></li>
                </ul>
              </nav>
              <nav className='off-canvas__nav off-canvas__nav--disclaimer'>
                <ul>
                  <li><span onClick={() => window && window.history.back()}>Back</span></li>
                </ul>
              </nav>
              <div className='off-canvas__footer'>
                <Link to='https://srl.com.au/' title='Sandhurst Retail &amp; Logistics' className='footer__logo'>
                  <img src={Srl} alt='Sandhurst Retail &amp; Logistics 2021' />
                </Link>
                <p>© Copyright Sandhurst Retail & Logisitics { new Date().getFullYear() }</p>
              </div>
            </div>
          </div>
        </header>
      </>
    )
  }
}

export default Header
