import React, { Component } from 'react'
import Link from '../utils/link'

import Fade from 'react-reveal/Fade'

import Srl from '../assets/images/srl.svg'
import gbca from '../assets/images/gbca.png'
import PDF from '../assets/images/SRL_OLP_EOI_July2023.pdf'
import Newsletter from './newsletter'

class Footer extends Component {
  render() {
    return (
      <>
        <section className='newsletter'>
          <Fade bottom distance='50px'>
            <div className='newsletter__inner'>
              { /* <a className='newsletter__download btn' href={PDF}>Download EOI Brochure</a> */ }
              <h3>Keep up to date</h3>
              <p>Be the first to know about new developments and updates regarding the O’Herns Logistics Park</p>
              <Newsletter />
            </div>
          </Fade>
        </section>
        <footer className='footer'>
          <div className='footer__inner'>
            <div className='footer__col'>
              <div className='footer__content'>
                <p>Sandhurst Retail &amp; Logistics is a diversified property group that’s redefining retail and logistics in Australia’s fastest growing regions to create places that connect people, business and culture.</p>
                <p>© Copyright Sandhurst Retail & Logistics { new Date().getFullYear() }</p>
              </div>
              <div className='footer__logos'>
                <Link className='logo' to='https://srl.com.au/' title='Sandhurst Retail &amp; Logistics'>
                  <img src={Srl} alt='Sandhurst Retail &amp; Logistics 2022' />
                </Link>
                <Link to='https://gbca.org.au/' title='GBCA' className='gbca'>
                  <img src={gbca} alt='Sandhurst Retail &amp; Logistics 2022' />
                </Link>
              </div>
            </div>
            <div className='footer__col'>
              <div className='footer__contact'>
                <div className='footer__address'>
                  <p>Level 2, 100 Dorcas Street<br />
                  South Melbourne, VIC 3205</p>
                  <p><Link to='mailto:general@srl.com.au'>general@srl.com.au</Link></p>
                </div>
                <div className='footer__credits'>
                  <p><Link to='https://www.linkedin.com/company/sandhurst-retail-and-logistics/?originalSubdomain=au'>LinkedIn</Link><br /><br /></p>
                  <p className='footer__opaque'>Site by <Link to='https://y-m-d.com'>YMD</Link></p>
                </div>
              </div>
              <div className='footer__links'>
                <ul className='footer__opaque'>
                  <li><Link to='/terms-of-use'>Terms of Use</Link></li>
                  <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
}

export default Footer
