import React, { Component } from 'react'
import { navigate } from 'gatsby'
import addToMailchimp from 'gatsby-plugin-mailchimp'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Newsletter extends Component {
  state = {
    email: '',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // 2. via `async/await`
  handleSubmit = async (e) => {
    e.preventDefault();
    let data = {}
    const result = await addToMailchimp(this.state.email, data)
    // I recommend setting `result` to React state
    // but you can do whatever you want
    if (result.result === 'success') navigate('/thank-you')
  }

  render() {
    let props = {
      ref: "form",
      name: "newsletter",
      className: "form form--newsletter",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    return (
      <form {...props}>
        <div className="form__row">
          <input
            type="email"
            name="email"
            placeholder="Email"
            onChange={this.handleChange}
            required
          />
          <button type="submit" className="btn">Submit</button>
        </div>
      </form>
    )
  }
}

export default Newsletter