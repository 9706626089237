import React, { useEffect, createRef } from 'react';

import lottie from 'lottie-web'
import animation from '../assets/animations/oherns.json'

const Intro = () => {
  let animationContainer = createRef()

  let timeout = null

  const onscroll = () => {
    if (window.scrollY - window.innerHeight > 0) {
      clearTimeout(timeout)
      window.removeEventListener('scroll', onscroll)
      document.querySelector('.site-wrapper').classList.add('complete')
      window.scrollBy(0, window.innerHeight * -1)
    }
  }

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: animation
    });
    timeout = setTimeout(() => {
      window.removeEventListener('scroll', onscroll)
    	document && document.querySelector('.site-wrapper').classList.add('animate')
    }, 3350)


    window && window.addEventListener('scroll', onscroll)

    return () => {
      window.removeEventListener('scroll', onscroll)
      anim.destroy()
    };

  }, []);

  return (
    <section className='intro'>
      <div className='intro__lottie' ref={animationContainer} />
    </section>
  )
}

export default Intro
